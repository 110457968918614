import React from 'react';
import { Card, CardContent, Typography, Avatar, Box, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';


const CompaniesCard = () => {
  const { t, i18n } = useTranslation(); // Import the translation function

  const companies = [
    { name: 'kiya bingo', description: '+251 92 852 5205 ', logo: 'K' },
    { name: 'Ya Bingo', description: '+251 91 116 9749', logo: 'YA' },
    { name: 'Havana', description: '+251 91 158 7462', logo: 'H' },
    { name: 'New Bingo', description: '+251 91 685 2540', logo: 'NB' },
    { name: 'Ashenafi ', description: '+251 91 268 6487', logo: 'A' },

  ];
  return (
    <Card
    sx={{
      background: 'linear-gradient(14deg, #1b105f 30%, #5948d5 90%)', // Purplish color
      color: '#fff',
      borderRadius: 2,
      boxShadow: 3,
      padding: 2,
    }}
  >
    <InputLabel sx={{ color: 'white' }}>{t('Companies')}</InputLabel>
  
    <CardContent>
      {companies.map((company, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: index < companies.length - 1 ? 2 : 0,
            cursor: 'pointer', // Change cursor to pointer on hover
          }}
          onClick={() => window.location.href = `tel:${company.description}`} // Initiate a phone call
        >
          <Avatar
            sx={{
              backgroundColor: '#6a0dad',
              width: 56,
              height: 56,
            }}
          >
            {company.logo}
          </Avatar>
          <Box sx={{ marginLeft: 2 }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
              {company.name}
            </Typography>
            <Typography sx={{ fontSize: '10px' }}>{company.description}</Typography>
          </Box>
        </Box>
      ))}
    </CardContent>
  </Card>
  
  
  );
};

export default CompaniesCard;
