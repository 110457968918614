import React from 'react';
import { Card, CardContent, Typography, Avatar, Box, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import logo from './components/logo.png'

const CompaniesInfo = () => {
  const { t, i18n } = useTranslation(); // Import the translation function

  const companies = [
    { name: 'Habesha Gaming', description: '+251 92 852 5205 ', logo: 'K' },


  ];

  return (
    <Card
    sx={{backgroundColor:'#1b105f'}}
    >
              <InputLabel sx={{ color:'white',  fontSize:'10px'}}>{t('Developed By')}</InputLabel>

      <CardContent>
        {companies.map((company, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: index < companies.length - 1 ? 2 : 0,
            }}
            onClick={() => window.location.href = `tel:${company.description}`} 
          >
            <Avatar
              sx={{
                backgroundColor: '#6a0dad',
                width: 26,
                height: 26,
              }}
            >
 <img
    src={logo}
    alt="Avatar"
    style={{
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '50%', // Optional, if you want to maintain the circular shape
    }}
  />            </Avatar>
            <Box sx={{ marginLeft: 2 }}>
              <Typography sx={{ fontWeight: 'bold', fontSize:'10px' ,color:'#FFF'}}>
                {company.name}
              </Typography>
              <Typography variant="body2" sx={{fontSize:'10px',color:'#FFF'}}>{company.description}</Typography>
            </Box>
          </Box>
        ))}
      </CardContent>
    </Card>
  );
};

export default CompaniesInfo;
